import { Ref, useRef } from "preact/hooks";
import { JSX } from "preact/jsx-runtime";
import { WIcon } from "./Window.tsx";

interface CornerMenuProps {
  title: JSX.Element | string
  show: string | [string, 'img' | 'iicon']
  children?: JSX.Element | string
}

export const CornerMenu = (props: CornerMenuProps) => {
  const self = useRef<HTMLElement>() as Ref<HTMLElement>

  const open = () => {
    if (self.current === null) return
    self.current.dataset.state = 'open'
  }
  const close = () => {
    if (self.current === null) return
    self.current.dataset.state = 'close'
  }

  return <aside id="menu">
    <header data-state="open" ref={self}>
      <h1 class="title s-open" onClick={close}>
        {props.title}
      </h1>
      <div class="show-btn s-close" onClick={open}>
        {typeof(props.show) === 'string'? props.show :
          <WIcon icon={props.show[0]} type={props.show[1]} />
        }
      </div>
      <nav class="s-open" style="margin-top: .5rem">
        {props.children}
      </nav>
    </header>
  </aside>
}